import { OmitSafe } from '@common/lib-types'
import {
  Citizenship,
  CitizenshipKeys,
  CustomersInfo,
  ERelationship,
  UpdateCustomersRequest,
} from '@common/types'
import { parseInteger } from '@src/utils'
import {
  getAuthToken,
  getLeadSourceQueryParams,
  getRequestId,
  LeadSourceKeys,
} from '@src/utils/storage'

import { consumerXpAggregatorUrl, refiApiUrl } from '../config'

export type CustomerInfo = {
  firstName: string
  lastName: string
  email: string
  birthdate: string
  phoneNumber: string
  ssn?: string
  citizenship: CitizenshipKeys
}

export type CreateCustomerResponse = {
  id: string
  segmentUserId: string
  authId: string
  requestId: string
  uuid: string
  resumeUrl: string
  newResumeUrl: string
  message?: string
}

export type PrequalCustomer = {
  citizenship: CitizenshipKeys
  estimatedYearlyIncome: string
}

export type CustomerSubmissionOptions = {
  queryParams: string
  leadSource: LeadSourceKeys
}

export type SubmitCustomer = (
  customer: CustomerInfo,
  options: CustomerSubmissionOptions,
) => Promise<CreateCustomerResponse>

/**
 * Submit customer for creation. This calls consumer-xp-flow-aggregator to create a
 * customer and any dependent resources.
 * @param CustomerInfo
 * @param CustomerSubmissionOptions
 * @returns CreateCustomerResponse
 */
export const submitCustomer: SubmitCustomer = async (
  customer,
  { queryParams, leadSource },
) => {
  const body = JSON.stringify({ ...customer, leadSource, queryParams })
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/submit-customer`,
    {
      method: 'POST',
      headers: {
        // This is a public endpoint so no auth needed
        'Content-Type': 'application/json',
      },
      body,
    },
  )
  const data: CreateCustomerResponse = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }

  return data
}

export type CreateCustomerWithCoborrowerResponse = CreateCustomerResponse & {
  coborrowerUuid: string
}

export type SubmitCustomerWithCoborrower = (
  customer: CustomerInfo,
  coborrower: CustomerInfo,
  relationship: ERelationship,
  options: CustomerSubmissionOptions,
) => Promise<CreateCustomerWithCoborrowerResponse>

/**
 * Submit customer with coborrowerfor creation. This calls consumer-xp-flow-aggregator
 * to create a customer and any dependent resources.
 * @param CustomerInfo customer
 * @param CustomerInfo coborrower
 * @param CustomerSubmissionOptions
 * @returns CreateCustomerResponse
 */
export const submitCustomerWithCoborrower: SubmitCustomerWithCoborrower =
  async (customer, coborrower, relationship, { queryParams, leadSource }) => {
    const coreRequest = {
      borrower: customer,
      coborrower,
      relationship,
    }
    const body = JSON.stringify({ ...coreRequest, queryParams, leadSource })
    const response = await fetch(
      `${consumerXpAggregatorUrl}/v1/submit-customer-with-coborrower`,
      {
        method: 'POST',
        headers: {
          // This is a public endpoint so no auth needed
          'Content-Type': 'application/json',
        },
        body,
      },
    )
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data?.message)
    }
    return data
  }
export type UpdateCustomers = (
  request: OmitSafe<UpdateCustomersRequest, 'requestId'>,
) => Promise<CustomersInfo>

export const updateCustomers: UpdateCustomers = async ({
  borrower,
  coborrower,
}) => {
  const body = JSON.stringify({
    // Somewhere along the line the types are getting screwed up.
    // Being explicit here fixes that causing api errors.
    requestId: getRequestId(),
    borrower: borrower
      ? {
          birthdate: borrower.birthdate,
          citizenship: borrower.citizenship,
          email: borrower.email,
          firstName: borrower.firstName,
          lastName: borrower.lastName,
          phoneNumber: borrower.phoneNumber,
          ssn: borrower.ssn,
        }
      : undefined,
    coborrower: coborrower
      ? {
          birthdate: coborrower.birthdate,
          citizenship: coborrower.citizenship,
          email: coborrower.email,
          firstName: coborrower.firstName,
          lastName: coborrower.lastName,
          phoneNumber: coborrower.phoneNumber,
          ssn: coborrower.ssn,
          relationship: coborrower.relationship,
        }
      : undefined,
  })
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/update-customers${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body,
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

const toRefiCitizenshipMap: Record<CitizenshipKeys, String> = {
  [Citizenship.UsCitizen]: 'us-citizen',
  [Citizenship.PermanentResident]: 'permanent-resident',
  [Citizenship.NonPermanentResident]: 'non-permanent-resident',
  [Citizenship.NonResidentAlien]: 'non-resident-alien',
}

export const refiUpdateCustomer = async (
  uuid: string,
  citizenship: CitizenshipKeys,
  estimatedYearlyIncome: string,
): Promise<void> => {
  const refiCitizenship = toRefiCitizenshipMap[citizenship]
  const refiEstimatedYearlyIncome = parseInteger(estimatedYearlyIncome)
  const response = await fetch(`${refiApiUrl}/services/v1/customer/${uuid}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body: JSON.stringify({
      citizenship: refiCitizenship,
      estimatedYearlyIncome: refiEstimatedYearlyIncome,
    }),
  })
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.errors)
  }
}

type RemoveCoborrower = () => Promise<{ hasCoborrower: boolean }>
export const removeCoborrower: RemoveCoborrower = async () => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/remove-coborrower${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
      body: JSON.stringify({ requestId: getRequestId() }),
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export type GetCustomers = (options?: {
  token?: string
  requestId?: string
}) => Promise<CustomersInfo>

export const getCustomers: GetCustomers = async (options) => {
  const requestId = options?.requestId ?? getRequestId()
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/get-customers${getLeadSourceQueryParams()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (options?.token ?? getAuthToken()),
      },
      body: JSON.stringify({ requestId }),
    },
  )

  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

export type ExpandedLoanApplication = {
  loanApplication: {
    aasmState: string
    estimatedMonthlyPayment: number
    estimatedRemainingLoanAmount: number
    id: string
    uuid: string
    multipleApplicants: boolean
    secondaryBorrowerRelationship: string
    createdAt: string
    updatedAt: string
    fundedAt?: string
    expiredAt?: string
    simplifiedStatus: 'funded' | 'expired' | 'inProgress'
    directMailApp: boolean
  }
  vehicle?: {
    uuid: string
    year?: number
    make?: string
    model?: string
    trim?: string
  }
  partnerLeadSource?: {
    sourceName: string
    requestId: string
  }
}

export const getExpandedLoanApplications = async (
  authToken: string,
  customerUuid: string,
): Promise<Array<ExpandedLoanApplication>> => {
  const response = await fetch(
    `${consumerXpAggregatorUrl}/v1/get-expanded-loan-applications?customerUuid=${customerUuid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    },
  )

  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return data
}

const fromRefiCitizenshipMap: Record<string, CitizenshipKeys> = {
  'us-citizen': Citizenship.UsCitizen,
  'permanent-resident': Citizenship.PermanentResident,
  'non-permanent-resident': Citizenship.NonPermanentResident,
  'non-resident-alien': Citizenship.NonResidentAlien,
}

export const getPrequalCustomer = async (
  primaryCustomerId: string,
): Promise<PrequalCustomer> => {
  const response = await fetch(
    `${refiApiUrl}/services/v1/customer/${primaryCustomerId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return {
    citizenship: fromRefiCitizenshipMap[data.citizenship],
    estimatedYearlyIncome: data.estimatedYearlyIncome,
  }
}

export const getPrequalCustomerByLoanApplicationUUID = async (
  loanApplicationUuid: string,
): Promise<PrequalCustomer> => {
  const response = await fetch(
    `${refiApiUrl}/services/v1/loan_application/${loanApplicationUuid}/customer_loan_applications`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getAuthToken(),
      },
    },
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error(data?.message)
  }
  return {
    citizenship: fromRefiCitizenshipMap[data.citizenship],
    estimatedYearlyIncome: data.estimatedYearlyIncome,
  }
}
